import { AllAccessRoles } from './constants/appRoles.ts';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { getAxios } from './util/axios';

(window as any).global ||= window;

(window as any).getAxios = getAxios;
(window as any).superAdminRoles = AllAccessRoles;

const container = document.getElementById('root')!;

const root = createRoot(container);
root.render(<App />);

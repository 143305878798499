import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { Layout } from '../components/layout/layout.tsx';
import { AppRoles } from '../constants/appRoles';
import { ApplicationRoutes } from '../constants/appRoutes.ts';

export const ReportRoutes: RouteItem = {
  text: 'Reports',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.Admin],
  path: ApplicationRoutes.inspectionAdmin.view,
  layout: <Layout />,
  async lazy() {
    const component = await import('../features/inspectionReportAdmin/InspectionReportAdmin');
    return { Component: component.InspectionReportAdmin };
  }
};

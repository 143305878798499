import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { Layout } from '../components/layout/layout.tsx';
import { AppRoles } from '../constants/appRoles';
import { ApplicationRoutes } from '../constants/appRoutes';

export const InspectionAdminRoutes: RouteItem = {
  text: 'Inspection Admin',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.UserAdmin, AppRoles.Admin],
  layout: <Layout />,
  subItems: [
    {
      text: 'Inspection Types',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.aql,
      allowedRoles: [AppRoles.Admin],
      async lazy() {
        const component = await import('../features/aqlAdmin/aqlAdmin');
        return { Component: component.AqlAdmin };
      }
    },
    {
      text: 'Variables',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.selectList,
      allowedRoles: [AppRoles.Admin],
      async lazy() {
        const component = await import('../features/selectListAdmin/selectListAdmin.tsx');
        return { Component: component.SelectListAdmin };
      }
    }
  ]
};

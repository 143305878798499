import { createSystem, defaultConfig, mergeConfigs } from '@chakra-ui/react';
import { buildBaseTheme } from '@cksoftware/react-base';

const baseTheme = buildBaseTheme({
  primaryColor: '#ff9328',
  secondaryColor: '#ff003f',
  tertiaryColor: '#64154a',
  buttons: {
    borderRadius: 4
  }
});

const merged = mergeConfigs(defaultConfig, baseTheme);
export default createSystem(merged);

import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { Layout } from '../components/layout/layout.tsx';
import { AppRoles } from '../constants/appRoles';
import { ApplicationRoutes } from '../constants/appRoutes';

export const FaultRoutes: RouteItem = {
  text: 'Faults',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.UserAdmin, AppRoles.Admin],
  layout: <Layout />,
  subItems: [
    {
      text: 'Fault Management',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.faults.manage,
      allowedRoles: [AppRoles.Admin],
      async lazy() {
        const component = await import('../features/faults/faultManagement/faultManagement.tsx');
        return { Component: component.FaultManagement };
      }
    },
    {
      text: 'Client Fault Management',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.faults.clientSpecific,
      allowedRoles: [AppRoles.Admin],
      async lazy() {
        const component = await import('../features/faults/clientFaultManagement/clientFaultManagement.tsx');
        return { Component: component.ClientFaultManagement };
      }
    },
    {
      text: 'Fault Category Management',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.faults.categories,
      allowedRoles: [AppRoles.Admin],
      async lazy() {
        const component = await import('../features/faults/faultCategoryManagement/FaultCategoryManagement.tsx');
        return { Component: component.FaultCategoryManagement };
      }
    }
  ]
};
